import { custom_headers } from '../../../../domain/headers';
import { APIList } from '../../../../typings/API.interface';
import { ApiKey } from '../../../../typings/ApiKey.interface';
import {
  APIBatchOperation,
  BatchOperationPlan,
} from '../../../../typings/BatchOperation.interface';
import { Bookmark } from '../../../../typings/Bookmark.interface';
import { CliClient } from '../../../../typings/CliClient.interface';
import { CliSession } from '../../../../typings/CliSession.interface';
import { CFCustomDomain, CustomDomainAddDeleteResult } from '../../../../typings/CustomDomain';
import { Destination } from '../../../../typings/Destination.interface';
import { Event } from '../../../../typings/Event.interface';
import { EventAttempt } from '../../../../typings/EventAttempt.interface';
import { IgnoredEvent } from '../../../../typings/IgnoredEvent.interface';
import { Issue, IssueWithData } from '../../../../typings/Issue.interface';
import IssueTrigger, { IssueTriggerChannels } from '../../../../typings/IssueTrigger.interface';
import { OrbPlan, OrbSubscription, StandardizedInvoice } from '../typings/Orb.interface';
import {
  Organization,
  OrganizationCreateInput,
  OrganizationUpdateInput,
  OrganizationWithRoleAndPlan,
} from '../../../../typings/Organization.interface';
import {
  OrganizationMember,
  OrganizationMemberInvite,
  OrganizationRole,
} from '../../../../typings/OrganizationMember.interface';
import { Request } from '../../../../typings/Request.interface';
import { SigningSecret } from '../../../../typings/SigningSecret.interface';
import { Source } from '../../../../typings/Source.interface';
import { Subscription } from '../../../../typings/Subscription.interface';
import { Team, TeamUpdateInput, TeamWithRole } from '../../../../typings/Team.interface';
import { TeamIntegration } from '../../../../typings/TeamIntegration.interface';
import { TeamMember, TeamRole } from '../../../../typings/TeamMember.interface';
import {
  Transformation,
  TransformationExecutorOutput,
  TransformationRunInput,
} from '../../../../typings/Transformation.interface';
import { TransformationExecution } from '../../../../typings/TransformationExecution.interface';
import { RedactedUser, User } from '../../../../typings/User.interface';
import { View, ViewCreateInput, ViewUpdateInput } from '../../../../typings/View.interface';
import { Webhook, WebhookFilterProperty } from '../../../../typings/Webhook.interface';
import { stringifyQuery } from '../utils';

type HookdeckAPIErrorResponse = {
  message: string;
  status: number;
  data: any;
  code: string;
};

export class HookdeckAPIError extends Error {
  response: string | HookdeckAPIErrorResponse | null;
  status: number;

  constructor(status: number, response: string | HookdeckAPIErrorResponse | null) {
    super('Hookdeck API Error');
    this.response = response;
    this.status = status;
  }
}

type Method = 'POST' | 'GET' | 'PUT' | 'DELETE';

const api_version = '2024-09-01';

export default class HookdeckAPI {
  api_url: string;
  team_id: string;
  local_cache: { [key: string]: { result: any; duration: number } };

  constructor(api_url: string, team_id: string) {
    this.api_url = api_url;
    this.team_id = team_id;
    this.local_cache = {};
  }

  request<T>(method: Method, path: string, data?: object, signal?: AbortSignal): Promise<T> {
    const team_id_header = this.team_id ? { [custom_headers.x_team_id]: this.team_id } : {};

    let url = `${this.api_url}/${api_version}${path}`;
    if (method === 'GET' && data) {
      url += `?${stringifyQuery(data)}`;
    }
    return fetch(url, {
      method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...team_id_header,
      },
      credentials: 'include',
      ...(method !== 'GET' && data ? { body: JSON.stringify(data) } : {}),
      ...(signal ? { signal } : {}),
    }).then(async (response) => {
      const json = await response.json().catch(() => null);
      if (response.ok) {
        return json as T;
      }
      throw new HookdeckAPIError(response.status, json);
    });
  }

  async requestWithCache<T>(
    method: Method,
    url: string,
    data: object = {},
    duration = 1000 * 5,
  ): Promise<T> {
    const key = `${method}-${url}-${JSON.stringify(data)}`;
    if (this.local_cache[key] && this.local_cache[key].duration > Date.now()) {
      return this.local_cache[key].result as T;
    }
    const result = await this.request(method, url, data);
    this.local_cache[key] = { result, duration: Date.now() + duration };
    return result as T;
  }

  billing = {
    getThroughputSkus: () =>
      this.request<{
        [key: string]: {
          allocation: number;
          price: number;
          discount: number;
          total_price: number;
          label: string;
          legacy?: boolean;
        };
      }>('GET', '/internal/billing/throughput/skus'),
    updateThroughput: (sku: string) =>
      this.request<Team>('POST', '/internal/billing/throughput/update', { sku }),
    getToken: () => this.request<{ client_secret: string }>('GET', '/internal/billing/token'),
    getSubscriptionDetails: () =>
      this.request<OrbSubscription>('GET', '/internal/billing/subscription/details'),
    selectPlan: (plan: string) =>
      this.request<Subscription | { sessionId: string }>('POST', '/internal/billing/checkout', {
        plan,
      }),
    getUsage: (filters?: object) =>
      this.request<{
        data: {
          usage: {
            quantity: number;
            timeframe_start: string;
            timeframe_end: string;
          }[];
          billable_metric: { id: string; name: string };
          view_mode: 'periodic' | 'cumulative';
        }[];
      }>('GET', '/internal/billing/usage', filters),
    getPlans: (filters?: object) =>
      this.request<OrbPlan[]>('GET', '/internal/billing/plans', filters),
    getInvoices: () => this.request<StandardizedInvoice[]>('GET', '/internal/billing/invoices'),
    getCard: () =>
      this.request<{
        brand: string;
        exp_month: number;
        exp_year: number;
        last4: string;
      }>('GET', '/internal/billing/card'),
    changePlan: (plan_name: string) =>
      this.request<Subscription>('POST', '/internal/billing/plan', { plan_name }),
    updateCard: () =>
      this.request<{
        sessionId: string;
      }>('POST', '/internal/billing/card'),
    cancel: () => this.request<Subscription>('PUT', '/internal/billing/cancel'),
    getBillingEmail: () => this.request<{ email: string }>('GET', '/internal/billing/email'),
    updateBillingEmail: (email) =>
      this.request<{ email: string }>('PUT', '/internal/billing/email', { email }),
    setPaymentMethodAsDefault: (payment_method_id: string) =>
      this.request<{ email: string }>('PUT', '/internal/billing/default_payment_method', {
        payment_method_id: payment_method_id,
      }),
  };

  users = {
    findByEmail: (email: string) => this.request<RedactedUser>('GET', '/users', { email }),
    delete: () => this.request<{ id: string }>('DELETE', `/session/me`),
  };

  completion = {
    completeFilter: (prompt: string, data: string) =>
      this.request<{ filter: string }>('POST', '/internal/completions/filter', { prompt, data }),
  };

  console = {
    getUserOrCreateGuest: () =>
      this.request<{ user: User; team: Team; cli_key: CliClient }>(
        'GET',
        '/internal/console/session',
      ),
  };

  status = {
    getServiceStatus: () => this.request<{ status: 'ok' | 'degraded' }>('GET', `/internal/status`),
  };

  track = {
    event: (event: string, data?: object) =>
      this.request<{ success: boolean }>('POST', '/internal/t', { event, data }).catch(() => false),
  };

  utils = {
    testFilters: (
      request: {
        headers?: object;
        body?: any;
        path?: string;
        parsed_query?: object;
      },
      schema: {
        headers?: WebhookFilterProperty;
        body?: WebhookFilterProperty;
        path?: WebhookFilterProperty;
        query?: WebhookFilterProperty;
      },
    ) =>
      this.request<[boolean, { headers: boolean; body: boolean; path: boolean; query: boolean }]>(
        'PUT',
        `/internal/test_filters`,
        { request, schema },
      ),
  };

  organizations = {
    listAll: () => this.request<OrganizationWithRoleAndPlan[]>('GET', `/organizations`),
    create: (data: OrganizationCreateInput) =>
      this.request<{ organization: Organization; default_team: Team }>(
        'POST',
        `/organizations`,
        data,
      ),
    update: (data: OrganizationUpdateInput) =>
      this.request<Organization>('PUT', `/organizations/current`, data),
    delete: () => this.request<{ id: string }>('DELETE', `/organizations/current`),
    leave: () => this.request<{ id: string }>('DELETE', `/organizations/current/leave`),
    getSubscription: () => this.request<Subscription>('GET', `/organizations/current/subscription`),
    getNextInvoice: () =>
      this.request<{
        currency: string;
        period_end: number;
        period_start: number;
        total: number;
      }>('GET', `/organizations/current/subscription/next_invoice`),
    listMembers: () => this.request<OrganizationMember[]>('GET', `/organizations/current/members`),
    listMemberInvites: () =>
      this.request<OrganizationMemberInvite[]>('GET', `/organizations/current/invites`),
    resendInvite: (invite_id: string) =>
      this.request<OrganizationMemberInvite>(
        'POST',
        `/organizations/current/invites/${invite_id}/resend`,
      ),
    revokeInvite: (invite_id: string) =>
      this.request<{ id: string }>('DELETE', `/organizations/current/invites/${invite_id}`),
    addMembers: (members: { email: string; role: OrganizationRole }[]) =>
      this.request<{
        members: OrganizationMember[];
        invites: OrganizationMemberInvite[];
        subscription: Subscription;
      }>('POST', `/organizations/current/members`, { members }),
    updateRole: (member_id: string, role: OrganizationRole) =>
      this.request<{ member: OrganizationMember }>(
        'PUT',
        `/organizations/current/members/${member_id}/role`,
        {
          role,
        },
      ),
    removeMember: (member_id: string) =>
      this.request<{ id: string }>('DELETE', `/organizations/current/members/${member_id}`),
    validateInviteToken: (id: string) =>
      this.request<{ organization_name: string }>('GET', `/internal/validate_invite_token/${id}`),
  };

  teams = {
    listAll: () => this.request<TeamWithRole[]>('GET', `/teams`),
    create: (data: { name: string; organization_id: string; private: boolean }) =>
      this.request<Team>('POST', `/teams`, data),
    update: (data: TeamUpdateInput) => this.request<Team>('PUT', `/teams/current`, data),
    delete: () => this.request<{ id: string }>('DELETE', `/teams/current`),
    transfer: (target_organization_id: string) =>
      this.request<{ id: string }>('POST', `/teams/current/transfer`, { target_organization_id }),
    leave: () => this.request<{ id: string }>('DELETE', `/teams/current/leave`),
    listMembers: () => this.request<TeamMember[]>('GET', `/teams/current/members`),
    addMembers: (members: { user_id: string; role: TeamRole }[]) =>
      this.request<TeamMember[]>('POST', `/teams/current/members`, { members }),
    updateRole: (member_id: string, role: TeamRole) =>
      this.request<{ member: TeamMember }>('PUT', `/teams/current/members/${member_id}/role`, {
        role,
      }),
    removeMember: (member_id: string) =>
      this.request<{ id: string }>('DELETE', `/teams/current/members/${member_id}`),
    createCustomDomain: (hostname: string) =>
      this.request<CustomDomainAddDeleteResult>('POST', `/teams/current/custom_domains`, {
        hostname,
      }),
    listCustomDomains: () => this.request<CFCustomDomain[]>('GET', `/teams/current/custom_domains`),
    deleteCustomDomain: (domain_id: string) =>
      this.request<CustomDomainAddDeleteResult>(
        'DELETE',
        `/teams/current/custom_domains/${domain_id}`,
      ),
  };

  issues = {
    get: (id: string) => this.request<IssueWithData>('GET', `/issues/${id}`),
    list: (filters = {}) => this.request<APIList<Issue>>('GET', '/issues', filters),
    count: (filters = {}) => this.request<{ count: number }>('GET', '/issues/count', filters),
    update: (id: string, data) => this.request<Issue>('PUT', `/issues/${id}`, data),
    dismiss: (id: string) => this.request<Issue>('DELETE', `/issues/${id}`),
  };

  issue_triggers = {
    get: (id: string) => this.request<IssueTrigger>('GET', `/issue-triggers/${id}`),
    list: (filters = {}) => this.request<APIList<IssueTrigger>>('GET', '/issue-triggers', filters),
    count: (filters = {}) =>
      this.request<{ count: number }>('GET', '/issue-triggers/count', filters),
    create: (data) => this.request<IssueTrigger>('POST', `/issue-triggers`, data),
    update: (id: string, data) => this.request<IssueTrigger>('PUT', `/issue-triggers/${id}`, data),
    delete: (id: string) => this.request<{ id: string }>('DELETE', `/issue-triggers/${id}`),
    disable: (id: string) => this.request<IssueTrigger>('PUT', `/issue-triggers/${id}/disable`),
    enable: (id: string) => this.request<IssueTrigger>('PUT', `/issue-triggers/${id}/enable`),
    nameIsUsed: (name: string): Promise<boolean> =>
      this.requestWithCache<APIList<IssueTrigger>>('GET', `/issue-triggers`, {
        name,
        limit: 1,
      }).then((data) => !!data.models[0]),
    attachChannelsToAll: (data: IssueTriggerChannels): Promise<IssueTrigger[]> =>
      this.request<IssueTrigger[]>('PUT', `/internal/issue-triggers/channels`, data),
  };

  webhooks = {
    get: (id: string) => this.request<Webhook>('GET', `/webhooks/${id}`),
    test: (id: string) => this.request<Webhook>('POST', `/webhooks/${id}/test`),
    create: (data: object) => this.request<Webhook>('POST', `/webhooks`, data),
    upsert: (data: object) => this.request<Webhook>('PUT', `/webhooks`, data),
    update: (id: string, data) => this.request<Webhook>('PUT', `/webhooks/${id}`, data),
    list: (filters = {}) => this.request<APIList<Webhook>>('GET', '/webhooks', filters),
    count: (filters = {}) => this.request<{ count: number }>('GET', '/webhooks/count', filters),
    disable: (id: string) => this.request<Webhook>('PUT', `/webhooks/${id}/disable`),
    enable: (id: string) => this.request<Webhook>('PUT', `/webhooks/${id}/enable`),
    pause: (id: string) => this.request<Webhook>('PUT', `/webhooks/${id}/pause`),
    unpause: (id: string) => this.request<Webhook>('PUT', `/webhooks/${id}/unpause`),
    delete: (id: string) => this.request<Pick<Webhook, 'id'>>('DELETE', `/webhooks/${id}`),
    nameIsUsed: (source_id: string, name: string): Promise<boolean> =>
      this.requestWithCache<APIList<Webhook>>('GET', `/webhooks`, {
        name,
        source_id,
        limit: 1,
      }).then((data) => !!data.models[0]),
  };

  destinations = {
    get: (id: string) => this.request<Destination>('GET', `/destinations/${id}`),
    delete: (id: string) => this.request<Pick<Destination, 'id'>>('DELETE', `/destinations/${id}`),
    create: (data: object) => this.request<Destination>('POST', `/destinations`, data),
    update: (id: string, data) => this.request<Destination>('PUT', `/destinations/${id}`, data),
    disable: (id: string) => this.request<Destination>('PUT', `/destinations/${id}/disable`),
    enable: (id: string) => this.request<Destination>('PUT', `/destinations/${id}/enable`),
    list: (filters = {}) => this.request<APIList<Destination>>('GET', '/destinations', filters),
    nameIsUsed: (name: string): Promise<boolean> =>
      this.requestWithCache<APIList<Destination>>('GET', `/destinations`, {
        name,
        limit: 1,
      }).then((data) => !!data.models[0]),
  } as const;

  sources = {
    get: (id: string, filters: object = {}) =>
      this.request<Source>('GET', `/sources/${id}`, filters),
    delete: (id: string) => this.request<Pick<Source, 'id'>>('DELETE', `/sources/${id}`),
    create: (data: object) => this.request<Source>('POST', `/sources`, data),
    update: (id: string, data) => this.request<Source>('PUT', `/sources/${id}`, data),
    disable: (id: string) => this.request<Source>('PUT', `/sources/${id}/disable`),
    enable: (id: string) => this.request<Source>('PUT', `/sources/${id}/enable`),
    list: (filters = {}) => this.request<APIList<Source>>('GET', '/sources', filters),
    nameIsUsed: (name: string): Promise<boolean> =>
      this.requestWithCache<APIList<Source>>('GET', `/sources`, {
        name,
        limit: 1,
      }).then((data) => !!data.models[0]),
  };

  bookmarks = {
    get: (id: string) => this.request<Bookmark>('GET', `/bookmarks/${id}`),
    getRawBody: (id: string) => this.request<{ body: string }>('GET', `/bookmarks/${id}/raw_body`),
    update: (id: string, data: object) => this.request<Bookmark>('PUT', `/bookmarks/${id}`, data),
    create: (data) => this.request<Bookmark>('POST', `/bookmarks`, data),
    list: (filters = {}) => this.request<APIList<Bookmark>>('GET', '/bookmarks', filters),
    trigger: (id: string, target: 'cli' | 'http') =>
      this.request<Event[]>('POST', `/bookmarks/${id}/trigger`, { target }),
    delete: (id: string) => this.request<{ id: string }>('DELETE', `/bookmarks/${id}`),
  };

  team_integrations = {
    create: (data) => this.request<TeamIntegration>('POST', `/session/team-integrations`, data),
    list: (filters = {}) =>
      this.request<APIList<TeamIntegration>>('GET', '/session/team-integrations', filters),
    delete: (id: string) =>
      this.request<{ id: string }>('DELETE', `/session/team-integrations/${id}`),
  };

  transformations = {
    get: (id: string) => this.request<Transformation>('GET', `/transformations/${id}`),
    update: (id: string, data: object) =>
      this.request<Transformation>('PUT', `/transformations/${id}`, data),
    create: (data) => this.request<Transformation>('POST', `/transformations`, data),
    list: (filters = {}) =>
      this.request<APIList<Transformation>>('GET', '/transformations', filters),
    listExecutions: (id: string, filters = {}) =>
      this.request<APIList<TransformationExecution>>(
        'GET',
        `/transformations/${id}/executions`,
        filters,
      ),
    getExecution: (id: string, execution_id: string) =>
      this.request<TransformationExecution>(
        'GET',
        `/transformations/${id}/executions/${execution_id}`,
      ),
    run: (props: TransformationRunInput) =>
      this.request<TransformationExecutorOutput>('PUT', `/transformations/run`, props),
    nameIsUsed: (name: string): Promise<boolean> =>
      this.requestWithCache<APIList<Transformation>>('GET', `/transformations`, {
        name,
        limit: 1,
      }).then((data) => !!data.models[0]),
  };

  requests = {
    get: (id: string) => this.request<Request>('GET', `/requests/${id}`),
    retry: (id: string, webhook_ids: string[] = []) =>
      this.request<{ request: Request; events: Event[] }>('POST', `/requests/${id}/retry`, {
        webhook_ids,
      }),
    getRawBody: (id: string) => this.request<{ body: string }>('GET', `/requests/${id}/raw_body`),
    list: (filters = {}, signal?: AbortSignal) =>
      this.request<APIList<Request>>('GET', '/requests', filters, signal),
    listEvents: (id: string, filters = {}) =>
      this.request<APIList<Event>>('GET', `/requests/${id}/events`, filters),
    listIgnoredEvents: (id: string, filters = {}) =>
      this.request<APIList<IgnoredEvent>>('GET', `/requests/${id}/ignored_events`, filters),
  };

  events = {
    get: (id: string) => this.request<Event>('GET', `/events/${id}`),
    getRawBody: (id: string) => this.request<{ body: string }>('GET', `/events/${id}/raw_body`),
    list: (filters = {}, signal?: AbortSignal) =>
      this.request<APIList<Event>>('GET', '/events', filters, signal),
    retry: (id: string) => this.request<Event>('POST', `/events/${id}/retry`),
    mute: (id: string) => this.request<Event>('PUT', `/events/${id}/mute`),
    testFilters: (
      id: string,
      filters: {
        headers?: WebhookFilterProperty;
        body?: WebhookFilterProperty;
        path?: WebhookFilterProperty;
        query?: WebhookFilterProperty;
      },
    ) =>
      this.request<[boolean, { headers: boolean; body: boolean; path: boolean; query: boolean }]>(
        'PUT',
        `/events/${id}/filter`,
        filters,
      ),
  };

  ignored_events = {
    list: (filters = {}, signal?: AbortSignal) =>
      this.request<APIList<IgnoredEvent>>('GET', '/ignored-events', filters, signal),
  };

  attempts = {
    get: (id: string) => this.request<EventAttempt>('GET', `/attempts/${id}`),
    list: (filters = {}) => this.request<APIList<EventAttempt>>('GET', '/attempts', filters),
  };

  bulk = {
    events: {
      get: (id: string) => this.request<APIBatchOperation>('GET', `/bulk/events/retry/${id}`),
      list: (filters = {}) =>
        this.request<APIList<APIBatchOperation>>('GET', '/bulk/events/retry', filters),
      count: (filters = {}) =>
        this.request<{ count: number }>('GET', '/bulk/events/retry/count', filters),
      plan: (filters) =>
        this.request<BatchOperationPlan>('GET', `/bulk/events/retry/plan`, filters),
      retry: (filters) =>
        this.request<APIBatchOperation>('POST', `/bulk/events/retry`, {
          ...filters,
        }),
      cancel: (id: string) =>
        this.request<APIBatchOperation>('POST', `/bulk/events/retry/${id}/cancel`),
    },
    requests: {
      get: (id: string) => this.request<APIBatchOperation>('GET', `/bulk/requests/retry/${id}`),
      list: (filters = {}) =>
        this.request<APIList<APIBatchOperation>>('GET', '/bulk/requests/retry', filters),
      count: (filters = {}) =>
        this.request<{ count: number }>('GET', '/bulk/requests/retry/count', filters),
      plan: (filters) =>
        this.request<BatchOperationPlan>('GET', `/bulk/requests/retry/plan`, filters),
      retry: (filters) =>
        this.request<APIBatchOperation>('POST', `/bulk/requests/retry`, {
          ...filters,
        }),
      cancel: (id: string) =>
        this.request<APIBatchOperation>('POST', `/bulk/requests/retry/${id}/cancel`),
    },
    ignored_events: {
      get: (id: string) =>
        this.request<APIBatchOperation>('GET', `/bulk/ignored-events/retry/${id}`),
      list: (filters = {}) =>
        this.request<APIList<APIBatchOperation>>('GET', '/bulk/ignored-events/retry', filters),
      count: (filters = {}) =>
        this.request<{ count: number }>('GET', '/bulk/ignored-events/retry/count', filters),
      plan: (filters) =>
        this.request<BatchOperationPlan>('GET', `/bulk/ignored-events/retry/plan`, filters),
      retry: (filters) =>
        this.request<APIBatchOperation>('POST', `/bulk/ignored-events/retry`, {
          ...filters,
        }),
      cancel: (id: string) =>
        this.request<APIBatchOperation>('POST', `/bulk/ignored-events/retry/${id}/cancel`),
    },
  };

  cli_clients = {
    list: (filters?: object) => this.request<APIList<CliClient>>('GET', '/cli', filters),
    getSessions: (filters?: object) => this.request<CliSession[]>('GET', '/cli-sessions', filters),
  };

  auth = {
    signin: (
      { email, password }: { email: string; password: string },
      query,
    ): Promise<{ redirect: string }> =>
      this.request('POST', `/signin/email`, { ...query, email, password }),
    signup: (
      { email, password, name }: { email: string; password: string; name: string },
      query,
    ): Promise<{ redirect: string }> =>
      this.request('POST', `/signup/email`, { ...query, email, password, name }),
    sendPasswordReset: (email): Promise<{ success: boolean }> =>
      this.request('POST', `/password/forgot`, { email }),
    resetPassword: (password, token): Promise<{ redirect: string }> =>
      this.request('POST', `/password/reset`, { password, token }),
    getSSOUrl: (email?: string, organization_slug?: string) =>
      this.request<{ url: string }>('GET', `/signin/workos/get-auth-url`, {
        email,
        organization_slug,
      }),
  };

  session = {
    getCurrentApiKey: () => this.request<{ key: string }>('GET', `/session/api_key`),
    getCurrentSigningSecret: () => this.request<{ key: string }>('GET', `/session/signing_secret`),
    me: () => this.request<User>('GET', `/session/me`),
    context: (): Promise<{
      team: Team;
      team_role: TeamRole;
      organization: Organization;
      organization_role: OrganizationRole;
      subscription: Subscription;
    }> => this.request('GET', `/session/context`),
    updateMe: (data: object) => this.request<User>('PUT', `/session/me`, data),
    updatePassword: (password: string, old_password: string) =>
      this.request<{ success: true }>('POST', `/session/me/change_password`, {
        password,
        old_password,
      }),

    claimCliAuthToken: (key): Promise<Team> => this.request('POST', `/cli-auth/claim`, { key }),
    logout: () => this.request<null | { redirect_url?: string }>('POST', '/session/logout'),
    rollApiKey: (id: string, data: { delay_sec: number }) =>
      this.request<ApiKey>('POST', `/session/api_keys/${id}/roll`, data),
    rollSigningSecret: (id: string, data: { delay_sec: number }) =>
      this.request<SigningSecret>('POST', `/session/signing_secrets/${id}/roll`, data),
  };

  stats = {
    token: (): Promise<{ token: string }> => this.request('GET', `/stats/token`),
  };

  views = {
    list: <SpecificView = View>(filters = {}) =>
      this.request<APIList<SpecificView>>('GET', '/views', filters),
    create: (data: ViewCreateInput) => this.request<View>('POST', `/views`, data),
    update: (id: string, data: ViewUpdateInput) => this.request<View>('PUT', `/views/${id}`, data),
    delete: (id: string) => this.request<{ id: string }>('DELETE', `/views/${id}`),
  };
}
